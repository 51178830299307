import Cookies from 'universal-cookie';
import axios from 'axios';

const DEFAULT_COOKIE_OPTIONS = {
    path: '/',
};

const cookies = new Cookies();

const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
        return null;
    }
};

export default {
    axiosInterceptor: null,

    authenticate: function () {
        var token = cookies.get(window._env_.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);

        if (!token) return false;

        this.loadTokens(token);

        return true;
    },

    getCurrentUserId: () => {
        const token = cookies.get(window._env_.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        if (!token) {
            return null;
        }
        const parsedToken = parseJwt(token);
        return parsedToken ? parsedToken.uid : null;
    },

    async getAuthorizationUri() {
        const redirectUri = `${window.location.origin}/sso/login`;
        const { data } = await axios.get(
            `${window._env_.REACT_APP_SERVER_URL}/admin/sso/authorization_uri?redirect_uri=${redirectUri}`,
        );
        return data.authorization_uri;
    },

    async login(state, code, device) {
        if (this.axiosInterceptor) axios.interceptors.request.eject(this.axiosInterceptor);
        this.axiosInterceptor = null;
        delete axios.defaults.headers.common['Authorization'];
        const redirectUri = `${window.location.origin}/sso/login`;
        const { data } = await axios.post(`${window._env_.REACT_APP_SERVER_URL}/admin/sso/login`, {
            state,
            code,
            device,
            redirect_uri: redirectUri,
        });
        this.saveTokens(data.token, data.keycloak_id_token);
    },

    saveTokens: function (jwtToken, keycloakIdToken) {
        // save user in cookie
        cookies.set(window._env_.REACT_APP_COOKIE_NAME, jwtToken, DEFAULT_COOKIE_OPTIONS);
        cookies.set(window._env_.REACT_APP_KEYCLOAK_ID_TOKEN_COOKIE, keycloakIdToken, DEFAULT_COOKIE_OPTIONS);
        this.loadTokens(jwtToken);
    },

    loadTokens: function (jwtToken) {
        // set header in axios with auth token
        axios.defaults.headers.common['Authorization'] = jwtToken;

        this.axiosInterceptor = axios.interceptors.response.use(
            function (response) {
                // Do something with response data
                return response;
            },
            function (error) {
                if (error.response.status === 401) {
                    window.location.href = '/login';
                } else {
                    return Promise.reject(error);
                }
            },
        );
    },

    getKeycloakIdToken() {
        return cookies.get(window._env_.REACT_APP_KEYCLOAK_ID_TOKEN_COOKIE, DEFAULT_COOKIE_OPTIONS);
    },

    logout: function () {
        if (this.axiosInterceptor) axios.interceptors.request.eject(this.axiosInterceptor);
        this.axiosInterceptor = null;
        delete axios.defaults.headers.common['Authorization'];
        cookies.remove(window._env_.REACT_APP_COOKIE_NAME, DEFAULT_COOKIE_OPTIONS);
        cookies.remove(window._env_.REACT_APP_KEYCLOAK_ID_TOKEN_COOKIE, DEFAULT_COOKIE_OPTIONS);
    },
};
