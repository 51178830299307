import axios from 'axios';

export default {
    cleanRedisUserCache: function () {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/tech/redis_user_cache/flush')
            .then((data) => data.data);
    },

    cleanRedisFileReadSasCache: function () {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/tech/file_read_sas_cache/flush')
            .then((data) => data.data);
    },

    createUserBatch: function (users) {
        return axios.post(window._env_.REACT_APP_SERVER_URL + '/admin/users', users).then((data) => data.data);
    },

    createDemoProjectBatch: function (users, demofile) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/demo', {
                users,
                demofile,
            })
            .then((data) => data.data);
    },

    addUsersToProjects: function (users, projects, role) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/project_users', {
                users,
                projects,
                role,
            })
            .then((data) => data.data);
    },
};
