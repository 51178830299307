import axios from 'axios';

export default {
    search: function (text, take) {
        take = take || 10;
        return axios
            .get(window._env_.REACT_APP_SERVER_URL + `/admin/users`, {
                params: {
                    $term: text,
                    take,
                },
            })
            .then((data) => data.data);
    },

    getById: function (id) {
        return axios.get(window._env_.REACT_APP_SERVER_URL + `/admin/users/${id}`).then((data) => data.data);
    },

    update: function (id, user) {
        return axios.patch(window._env_.REACT_APP_SERVER_URL + '/admin/users/' + id, user).then((data) => data.data);
    },

    loginAs: function (email) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/login_as', {
                email: email,
            })
            .then((data) => data.data);
    },
};
