import React, { Component } from 'react';
import Promise from 'bluebird';
import { Container, Row, Col } from 'reactstrap';
import { parse } from 'query-string';
import NotificationSystem from 'react-notification-system';
import update from 'immutability-helper';
import User from '../api/User.js';
import UserForm from './UserForm';

import UserSearchBar from './UserSearchBar';

class UserComponent extends Component {
    constructor(props) {
        super(props);
        this._notificationSystem = null;

        this.state = {
            selectedUser: null,
            loading: false,
        };

        this.update = this.update.bind(this);
        this.displayUser = this.displayUser.bind(this);
        this.localUpdate = this.localUpdate.bind(this);
        this.loginAs = this.loginAs.bind(this);
        this.loginAsMobile = this.loginAsMobile.bind(this);
        this.forceFullSync = this.forceFullSync.bind(this);
        this.refreshUser = this.refreshUser.bind(this);

        const query = parse(window.location.search);

        if (query.user_id) {
            this.displayUser({ id: query.user_id });
        }
    }

    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }

    async displayUser({ id }) {
        const user = await User.getById(id);
        user.end_premium = new Date(user.end_premium).toISOString().slice(0, 10);
        this.setState({ selectedUser: user, users: [], loading: false });
    }

    async forceFullSync() {
        await this.setState({ forceFullSyncLoading: true });
        await User.update(this.state.selectedUser.id, {
            force_full_sync_enabled_at: new Date().getTime(),
        });
        await this.displayUser({ id: this.state.selectedUser.id });
        await this.setState({ forceFullSyncLoading: false });
    }

    async refreshUser() {
        await this.setState({ refreshingUserDataLoading: true });
        await Promise.all([this.displayUser({ id: this.state.selectedUser.id }), Promise.delay(500)]);
        await this.setState({ refreshingUserDataLoading: false });
    }

    async update(userData) {
        await this.setState({ loading: true });
        const user = {
            firstname: userData.firstname,
            lastname: userData.lastname,
            email: userData.email,
            mobile: userData.mobile,
            mail_notification: userData.mail_notification,
            license: userData.license,
            end_premium: userData.end_premium,
            email_verified_at: userData.email_verified_at,
            max_web_device: userData.max_web_device,
            max_mobile_device: userData.max_mobile_device,
            enforce_mobile_login_at: userData.enforce_mobile_login_at,
            mobile_verified_at: userData.mobile_verified_at,
            enforce_mobile_check: userData.enforce_mobile_check,
            enforce_mobile_login: userData.enforce_mobile_login,
            force_full_sync_enabled_at: userData.force_full_sync_enabled_at,
        };
        // set premium quota
        if (
            user.license === 'ExtendedTrial' ||
            user.license === 'Enterprise' ||
            user.license === 'ExtendedEnterprise' ||
            user.license === 'Premium'
        ) {
            user.premium_quota = 50000;
        } else if (user.License === 'Professional') {
            user.premium_quota = 1000;
        } else {
            user.premium_quota = 0;
        }

        // parse date
        if (user.end_premium && typeof user.end_premium === 'string') {
            user.end_premium = Date.parse(user.end_premium);
        }

        try {
            const updatedUser = await User.update(userData.id, user);
            this.displayUser(updatedUser);
            this._notificationSystem.addNotification({
                message: `User saved with success !`,
                level: 'success',
            });
        } catch (error) {
            this._notificationSystem.addNotification({
                message:
                    error.response &&
                    error.response.data &&
                    error.response.data.meta &&
                    error.response.data.meta.error_message
                        ? error.response.data.meta.error_message
                        : `Failed to save user !`,
                level: 'error',
            });
        }
        await this.setState({ loading: false });
    }

    async localUpdate(field, value) {
        // it's not possible to disable mobile verification if mobile login is true
        if (
            field === 'enforce_mobile_check' &&
            value === false &&
            this.state.selectedUser?.enforce_mobile_login === true
        ) {
            this.localUpdate('enforce_mobile_check', true);
            return null;
        }
        var newState = update(this.state, {
            selectedUser: {
                [field]: { $set: value },
            },
        });
        await this.setState(newState);

        // display warning if date is in the past
        if (field === 'end_premium') {
            var newDate = Date.parse(value);
            if (newDate < new Date().getTime()) {
                this._notificationSystem.addNotification({
                    message: `Be careful, this expiration is in the past, the user won't have access to the app anymore !`,
                    level: 'warning',
                });
            }
        }

        // if the SMS 2FA is enabled, enforce mobile check should be true too
        if (field === 'enforce_mobile_login' && value === true) {
            this.localUpdate('enforce_mobile_check', true);
        }
    }

    async loginAs() {
        try {
            const response = await User.loginAs(this.state.selectedUser.email);
            // delete useless attributes
            delete response.roles;
            window.open(
                window._env_.REACT_APP_WEBAPP_URL + '?force_token=' + encodeURIComponent(JSON.stringify(response)),
            );
        } catch (e) {
            console.error(e);
            this._notificationSystem.addNotification({
                message: `Login as this user failed :/`,
                level: 'error',
            });
        }
    }

    isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    }

    async loginAsMobile() {
        try {
            // Safari has a bug on the Clipboard API
            // https://bugs.webkit.org/show_bug.cgi?id=222262
            if (this.isSafari()) {
                const getJsonAsPromiseForSafari = async () => {
                    const response = await User.loginAs(this.state.selectedUser.email);
                    return JSON.stringify(response);
                };
                // eslint-disable-next-line no-undef
                await navigator.clipboard.write([new ClipboardItem({ 'text/plain': getJsonAsPromiseForSafari() })]);
            } else {
                const response = await User.loginAs(this.state.selectedUser.email);
                await navigator.clipboard.writeText(JSON.stringify(response));
            }

            this._notificationSystem.addNotification({
                message: `JSON saved to clipboard`,
                level: 'success',
            });
        } catch (e) {
            console.error(e);
            this._notificationSystem.addNotification({
                message: `Login as this user failed :/`,
                level: 'error',
            });
        }
    }

    render() {
        return (
            <div>
                <Container className="mt-4">
                    <Row>
                        <Col>
                            <UserSearchBar
                                size="lg"
                                userChanged={this.displayUser}
                                userCleared={() => this.setState({ selectedUser: null })}
                            />
                        </Col>
                    </Row>

                    {this.state.selectedUser && (
                        <UserForm
                            user={this.state.selectedUser}
                            localUpdate={this.localUpdate}
                            update={this.update}
                            loginAs={this.loginAs}
                            loginAsMobile={this.loginAsMobile}
                            forceFullSync={this.forceFullSync}
                            loading={this.state.loading}
                            forceFullSyncLoading={this.state.forceFullSyncLoading}
                            refreshingUserDataLoading={this.state.refreshingUserDataLoading}
                            refreshUser={this.refreshUser}
                        />
                    )}

                    <NotificationSystem ref="notificationSystem" />
                </Container>
            </div>
        );
    }
}

export default UserComponent;
