import axios from 'axios';

export default {
    get: function () {
        return axios.get(window._env_.REACT_APP_SERVER_URL + '/admin/organizations').then((data) => data.data);
    },

    create: function (organization) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/organizations', organization)
            .then((data) => data.data);
    },

    update: function (organizationId, organization) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId, organization)
            .then((data) => data.data);
    },

    delete: function (organizationId) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId, {
                is_deleted: true,
            })
            .then((data) => data.data);
    },

    getTeams: function (organizationId) {
        return axios
            .get(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/teams')
            .then((data) => data.data);
    },

    getBillingGroups: function (organizationId) {
        return axios
            .get(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/billing_groups')
            .then((data) => data.data);
    },

    getAdmins: function (organizationId) {
        return axios
            .get(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/admins')
            .then((data) => data.data);
    },

    addAdmin: function (organizationId, userId) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/admins', {
                user_id: userId,
            })
            .then((data) => data.data);
    },

    deleteAdmin: function (organizationId, userId) {
        return axios
            .delete(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/admins/' + userId)
            .then((data) => data.data);
    },

    getSasImage: function (organizationId, fileWidth, fileHeight, version) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/sas', {
                file_width: fileWidth,
                file_height: fileHeight,
                version,
            })
            .then((data) => data.data);
    },

    uploadImage: function (url, file) {
        return fetch(url, {
            method: 'PUT',
            headers: {
                'x-ms-blob-type': 'BlockBlob',
                'Content-Type': 'image/png',
            },
            body: file,
        });
    },
};
