import axios from 'axios';

export default {
    create: function (organizationId, billingGroup) {
        return axios
            .post(
                window._env_.REACT_APP_SERVER_URL + '/admin/organizations/' + organizationId + '/billing_groups',
                billingGroup,
            )
            .then((data) => data.data);
    },

    update: function (billingGroupId, billingGroup) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/billing_groups/' + billingGroupId, billingGroup)
            .then((data) => data.data);
    },

    delete: function (billingGroupId) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/billing_groups/' + billingGroupId, {
                is_deleted: true,
            })
            .then((data) => data.data);
    },
};
