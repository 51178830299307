import React, { Component } from 'react';
import { Alert, Button, Container, Col, Row, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import Auth from './Auth';
import { withRouter } from 'react-router';
import deviceDetector from 'ua-device-detector';
import packageJson from '../../package.json';

class LoginComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {};

        this.login = this.login.bind(this);
    }

    async logout() {
        Auth.logout();
        // Redirect to account, because at this point we don't have the keycloak id token, so we can really logout the user from Keycloak
        window.location = `${window._env_.REACT_APP_KEYCLOAK_URL}/auth/realms/${window._env_.REACT_APP_KEYCLOAK_REALM}/account`;
    }

    async login() {
        try {
            this.setState({
                error: null,
            });
            const queryParams = new URLSearchParams(this.props.location.search);
            const state = queryParams.get('state');
            const code = queryParams.get('code');
            if (!state || !code) {
                throw new Error('missing_data');
            }
            const deviceInfo = deviceDetector.parseUserAgent(navigator.userAgent);
            const device = {
                name: deviceInfo.browser,
                platform: 'INTERNAL_ADMIN_TOOL',
                os: deviceInfo.os,
                app_version: packageJson.version,
                system_version: deviceInfo.os_version,
            };
            await Auth.login(state, code, device);
            this.props.history.replace('/');
        } catch (e) {
            if (e.response && e.response.data && e.response.data.meta.error_message === 'NOT_ADMIN') {
                this.setState({
                    error: 'not-admin',
                });
            } else {
                console.error(e);
                this.setState({
                    error: 'login-failed',
                });
            }
        }
    }

    redirect() {
        this.props.history.replace('/');
    }

    async redirectToKeycloak() {
        const authorizationUri = await Auth.getAuthorizationUri();
        window.location = authorizationUri;
    }

    componentDidMount() {
        this.login();
    }

    render() {
        const { error } = this.state;
        return (
            <div className="background-bz auth-wrapper">
                <Container>
                    <Row className="vertical-offset-100">
                        <Col md={{ size: 4, offset: 4 }}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="text-center">
                                        <h2>Login</h2>
                                    </CardTitle>
                                    <CardText>
                                        {error && error === 'not-admin' && (
                                            <Alert color="danger">You are not admin</Alert>
                                        )}
                                        {error && error === 'login-failed' && (
                                            <Alert color="danger">Login failed, please retry</Alert>
                                        )}

                                        <Button onClick={this.redirectToKeycloak}>Login</Button>
                                        <Button color="danger" className="float-right" onClick={this.logout}>
                                            Logout
                                        </Button>
                                    </CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withRouter(LoginComponent);
