import axios from 'axios';

export default {
    get: function (take, skip) {
        return axios.get(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates').then((data) => data.data);
    },

    getById: function (id) {
        return axios.get(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates/' + id).then((data) => data.data);
    },

    create: function (reportTemplate) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates', reportTemplate)
            .then((data) => data.data);
    },

    update: function (id, reportTemplate) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates/' + id, reportTemplate)
            .then((data) => data.data);
    },

    assignToUser: function (id, email) {
        return axios
            .post(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates/' + id + '/users', { email })
            .then((data) => data.data);
    },

    removeAssignment: function (id, userId) {
        return axios
            .patch(window._env_.REACT_APP_SERVER_URL + '/admin/report_templates/' + id + '/users/' + userId, {
                is_deleted: true,
            })
            .then((data) => data.data);
    },
};
